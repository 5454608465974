/* purgecss start ignore */
@tailwind base;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
@font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-Regular.woff') format('woff');
    font-display: block;
}

@font-face {
    font-family: 'Matter';
    src: url('/fonts/Matter-SemiBold.woff') format('woff');
    font-weight: 500;
    font-display: block;
}

@font-face {
    font-family: 'Matter SQ';
    src: url('/fonts/MatterSQ-Regular.woff') format('woff');
    font-display: block;
}

@font-face {
    font-family: 'Matter SQ';
    src: url('/fonts/MatterSQ-SemiBold.woff') format('woff');
    font-weight: bold;
    font-display: block;
}

@tailwind components;

.mobilePreviewContainer::-webkit-scrollbar {
    display: none;
}

em {
    font-style: italic;
}
strong {
    font-weight: bold;
}

html {
    line-height: 1 !important;
}

.header-text h1 {
    font-size: 2.8em;
}

.header-text h2 {
    font-size: 2.625em;
}

.header-text h3 {
    font-size: 1.875em;
}

.header-text h4 {
    font-size: 1.25em;
}

.header-text h5 {
    font-size: 0.85em;
}

.header-text h6 {
    font-size: 0.7em;
}

.header-text p {
    font-size: 1em;
}

.placeholder-dnd ~ div {
    transform: none !important;
}

.placeholder-dnd ~ [data-rbd-placeholder-context-id] {
    display: none !important;
}

.ghost-drag {
    margin: 0 auto;
    width: 30%;
}

#react-qrcode-logo {
    max-width: 200px;
    max-height: 200px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.Toastify__toast--warning {
    background: #ffe8bc !important;
}

.Toastify__toast--error {
    background: #f5393d !important;
}

.Toastify__toast--success {
    background: #3ae29b !important;
}

.Toastify__toast > svg {
    color: white;
}

.Toastify__toast > button {
    color: white;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    margin-right: 5px;
    transform: scale(1.25);
}

.toastBody {
    font-family: 'Matter', sans-serif;
    color: white; /* #10171D */
    font-size: 14px !important;
    border-radius: 9px !important;
    margin: 0 0 0 5px !important;
}

.width_80 {
    width: 80%;
}

.tox-toolbar__overflow > div:first-child {
    display: none;
}

/**
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    overflow: visible;
}


::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}


::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
}


::-webkit-scrollbar-thumb:hover {
    background: #555;
}
**/
/* purgecss end ignore */
@tailwind utilities;
